<template>
  <div v-for="(saleAttrItem, index) in saleAttr" :key='index'>
    {{ saleAttrItem.name }}
    <div style="display:flex">
      <button :disabled="optionItem.disabled" :class="{ 'border': saleAttrItem.check === optionItem.name }"
        @click='clickBack(saleAttrItem, optionItem)' v-for="(optionItem, index1) in saleAttrItem.list" :key='index1'
        style="margin:10px">
        {{ optionItem.name }}
      </button>

    </div>

  </div>

  <div v-for="(item, index) in list" :key="index" style="margin:10px ;">
    <span v-for="(item1, index1) in item.productAttributeNameList" :key="index1" style="margin:10px;">
      {{ item1.name }}
    </span>
  </div>
</template>
  
<script>
// import { aaaaa } from "@/api/card";


export default {

  data() {
    return {
      saleAttr:
        [
          {
            name: '颜色',
            // check:'黄色',
            list: [

              { name: '白色' },
              { name: '红色' }
            ]
          },
          {
            name: '尺寸',
            // check:'黄色',
            list: [
              { name: '64G' },
              { name: '128G' },
              { name: '256G' }
            ]
          },
          {
            name: '材质',
            // check:'黄色',
            list: [
              { name: '国版' },
              { name: '美版' },
              // {name:'红色'}
            ]
          }
        ],
      skus: [
        ['红色', '128G', '国版'],
        ['红色', '128G', '美版'],
        ['红色', '128G'],
        ['红色', '256G'],
        ['白色', '128G', '国版'],
        ['红色', '64G', '国版'],
      ],
      list: []
    }
  },
  methods: {
    clickBack(saleAttrItem, optionItem) {
      if (saleAttrItem.check === optionItem.name) {
        saleAttrItem.check = ''
        // vm.$set(saleAttrItem,'check','')
      } else {
        saleAttrItem.check = optionItem.name
        // vm.$set(saleAttrItem,'check',optionItem.name)
      }
      this.computeCanClick()
    },
    computeCanClick() {
      // console.log(this);
      // var vm = this;
      this.saleAttr.forEach(saleAttrItem => {
        // console.log(saleAttrItem);
        saleAttrItem.list.forEach(optionItem => {
          let result = this.findSku(saleAttrItem, optionItem)
          if (result.length > 0) {
            optionItem.disabled = false
            // vm.$set(optionItem,'disabled',false)
          } else {
            optionItem.disabled = true
            // vm.$set(optionItem,'disabled',true)
          }
        })
      })
    },
    findSku(saleAttrItem, optionItem) {
      let restSku = this.skus.filter(skuItem => {
        return skuItem.includes(optionItem.name)
      })
      this.saleAttr.forEach(saleAttr1 => {
        if (saleAttr1.name !== saleAttrItem.name && (saleAttr1.check)) {
          restSku = restSku.filter(restSkuItem => {
            return restSkuItem.includes(saleAttr1.check)
          })
        }
      })
      return restSku
    }
  },
  mounted() {
    this.computeCanClick()
  },
  created() {
    // aaaaa().then(res => {
    //   this.list = res.data
    // })
  }

}
</script>
<style>
.border {
  border: 3px solid red
}
</style>
  
  